<main class="fullPage">
  <div class="middle">
    <img alt="404" src="../../assets/img/404.png" />
    <p>{{ '404.NOTFOUND' | translate }}</p>

    <button [routerLink]="['/']" class="btn-large btn-green">
      {{ '404.BUTTON' | translate }}
    </button>
  </div>
</main>
