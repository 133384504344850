import { Disturbance } from './../models/disturbance';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from 'src/configservice';
//import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisturbanceService {
  userId: string;
  

  constructor(private http: HttpClient, private configService: ConfigService) {}

  /** Get active disturbances */
  getActiveDisturbance(): Observable<Disturbance[]> {
    const url = `${this.configService.config.apiUrl}/Disturbance/active`;
    return this.http
      .get<Disturbance[]>(url)
      .pipe(
        catchError(this.handleError<Disturbance[]>(`get distrubances `, []))
      );
  }

  /** Error Handling */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      return of(result as T);
    };
  }
}
