import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/configservice';
import { LoggedInUser } from './shared/models/loggedInUser';
import { AuthService } from './shared/services/auth.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  constructor(translate: TranslateService, configService: ConfigService, 
    private primengConfig: PrimeNGConfig) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('sv');
    //console.log('config ', configService.config);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('sv');

    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100,   // tooltip
      dropdown: 1399,  // dropdown
      multiselect: 1399,
  };
    this.primengConfig.setTranslation({
      accept: 'Acceptera',
      reject: 'Avvisa',
      choose: 'Välj',
      upload: 'Ladda upp',
      cancel: 'Avbryt',
      firstDayOfWeek: 1,
      dateFormat: 'dd/mm/yy',
      dayNames: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
      dayNamesShort: ['Sö', 'Må', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
      dayNamesMin: ['Sö', 'Må', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
      monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
      contains: 'Innehåller',
      notContains: 'Innehåller ej',
      startsWith: 'Börjar med',
      endsWith: 'Slutar med',
      equals: 'Är lika med',
      notEquals: 'Är ej lika med',
      noFilter: 'Ingen filter',
      lt: 'Mindre än',
      lte: 'Mindre eller lika med',
      gt: 'Storre än',
      gte: 'Storre eller lika med',
      dateIs: 'Datum är',
      dateIsNot: 'Datum är ej',
      dateBefore: 'Datum är innan',
      dateAfter: 'Datum är efter',
      selectionMessage: '{0} valda objekt',
      emptyMessage: 'Inga objekt att visa',
      emptyFilterMessage: 'Inga objekt hittades',
  });
}

}