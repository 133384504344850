import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
  })

export class GeolocationService {

    public userPosition: Observable<any>;

    getCurrentPosition() {
        // if (navigator.geolocation) {
        //     return navigator.geolocation.getCurrentPosition(
        //       (position: Position) => {
        //         const pos = {
        //           lat: position.coords.latitude,
        //           lng: position.coords.longitude,
        //         };
        //       },
        //       () => {
        //         console.log('Could not get geolocation');
        //       }
        //     );
        //   } else {
        //     // Browser doesn't support Geolocation
        //     console.log('Browser does not support geolocation');
        //   }
    
    
    
        // this.userPosition = Observable((observer: Observer<Position>) => {
        //     // Invokes getCurrentPosition method of Geolocation API.
        //     navigator.geolocation.getCurrentPosition(
        //         (position: Position) => {
        //             observer.next(position);
        //             observer.complete();
        //         },
        //         (error: PositionError) => {
        //             console.log('Geolocation service: ' + error.message);
        //             observer.error(error);
        //         }
        //     );
        // });

        this.userPosition = new Observable((observer: any) => {
            navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    observer.next(position);
                    observer.complete();
                },
                (error: any) => {
                    console.log('Geolocation service: ' + error.message);
                    observer.error(error);
                }
            );
          });
    }
}
