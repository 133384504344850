import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Log } from 'oidc-client';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { ConfigService } from 'src/configservice';
import { AuthOrder } from '../models/authorder';
import { AuthResponse } from '../models/authresponse';
import { LoggedInUser } from '../models/loggedInUser';
//import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<LoggedInUser>;
  public currentUser: Observable<LoggedInUser>;

  constructor(private http: HttpClient, private configService: ConfigService) {

    this.currentUserSubject = new BehaviorSubject<LoggedInUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoggedInUser {
    return this.currentUserSubject.value;
  }

  public getEnvironment(): string {
    return this.configService.config.environment;
  }

  login(personalNumber: string): Observable<AuthOrder> {
    const url = `${this.configService.config.apiUrl}/Authentication/bankid/${personalNumber}`;
    return this.http.post<AuthOrder>(url, null).pipe();
  }

  collectResponse(authOrder: AuthOrder): Observable<AuthResponse> {
   const url = `${this.configService.config.apiUrl}/Authentication/bankid/${authOrder.orderRef}`;
   return this.http.get<AuthResponse>(url).pipe();
   
  }

  registerUser(userId: string, user) {
    const url = `${this.configService.config.apiUrl}/Authentication/user/${userId}/register`;
    return this.http.post<LoggedInUser>(url, user, {observe: 'response'}).pipe();
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  setCurrentUserSubject(loggedInUser: LoggedInUser) {
    this.currentUserSubject.next(loggedInUser);
  }

  testLogin(userid: string): Observable<AuthResponse> {
    const url = `${this.configService.config.apiUrl}/Authentication/testtoken/${userid}`;
    return this.http.get<AuthResponse>(url).pipe();
  }

  /** Error Handling */
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     console.error(error); // log to console
  //     return of(result as T);
  //   };
  // }
}
