import { FilterPipe } from './shared/filter.pipe';
import { GeolocationService } from './shared/services/geolocation.service';
import { OrderService } from './shared/services/order.service';
import { TripService } from './shared/services/trip.service';
import { DisturbanceService } from './shared/services/disturbance.service';
import { UserService } from './shared/services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import localeSw from '@angular/common/locales/sv';
registerLocaleData(localeSw, 'sv');

import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';

import { AppComponent } from './app.component';
import { HeaderComponent } from './client/components/header/header.component';
import { FooterComponent } from './client/components/footer/footer.component';
import { NavigationComponent } from './client/components/navigation/navigation.component';
import { LoginComponent } from './client/components/login/login.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { DashboardComponent } from './client/components/dashboard/dashboard.component';
import { SearchTripComponent } from './client/components/search-trip/search-trip.component';
import { MyTripsComponent } from './client/components/my-trips/my-trips.component';
import { MyAccountComponent } from './client/components/my-account/my-account.component';
import { DisruptionsComponent } from './client/components/disruptions/disruptions.component';
import { AddchildComponent } from './client/components/addchild/addchild.component';
import { SearchResultsComponent } from './client/components/search-results/search-results.component';
import { SelectedTripComponent } from './client/components/selected-trip/selected-trip.component';
import { BookingModalComponent } from './client/components/booking-modal/booking-modal.component';
import { ShowTicketModalComponent } from './client/components/show-ticket-modal/show-ticket-modal.component';
import { MapsModalComponent } from './client/components/maps-modal/maps-modal.component';
import { CancelTicketModalComponent } from './client/components/cancel-ticket-modal/cancel-ticket-modal.component';
import { OnboardingModalComponent } from './client/components/onboarding-modal/onboarding-modal.component';
import { TermsComponent } from './client/components/terms/terms.component';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { ConfirmRelativeModalComponent } from './client/components/confirm-relative-modal/confirm-relative-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConfigService } from 'src/configservice';
import { FaqComponent } from './client/components/faq/faq.component';
import { ClientFAQItemComponent } from './client/components/client-faqitem/client-faqitem.component';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConfirmationService, MessageService } from 'primeng/api';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem('jwt');
}

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'sv',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
            },
        }),
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
              clientId: environment.azureEntra.clientId,
              redirectUri: environment.azureEntra.redirectUri,
              navigateToLoginRequestUrl: false,
              authority: 'https://login.microsoftonline.com/' + environment.azureEntra.tenantId,
            },
            cache: {
              cacheLocation: BrowserCacheLocation.SessionStorage,
              storeAuthStateInCookie: true      },
      
          }),
            {
              interactionType: InteractionType.Redirect, // MSAL Guard Configuration
              loginFailedRoute: "/",
              authRequest: {
                scopes: ['openid']
              }
      
            }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
              ["admin", ['.default', 'openid', 'profile']],
              ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
              ["api/AdAuth/*/", ['api://' + environment.azureEntra.clientId + "/access"]],
              ["api/*/AdAuth/", ['api://' + environment.azureEntra.clientId + "/access"]],
            ])
          }),
          ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        ],
        exports: [
          TranslateModule,  
        ],
    providers: [
        UserService,
        GeolocationService,
        TripService,
        ConfirmationService,
        MessageService, 
        OrderService,
        DisturbanceService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
          },
          MsalService,
          MsalGuard,
          MsalBroadcastService,
          {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
