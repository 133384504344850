import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Municipality } from '../models/municipality';
import { Trip } from '../models/trip';
import { Stop } from '../models/stop';
// import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/configservice';

@Injectable({
  providedIn: 'root',
})
export class TripService {
  userId: string;
  // private tripUrl = 'https://localhost:44307/api/Trip';

  constructor(private http: HttpClient, private configService: ConfigService) {}

  /** Get active disturbances */
  getMunicipalities(): Observable<Municipality[]> {
    const url = `${this.configService.config.apiUrl}/Trip/municipalities`;
    return this.http
      .get<Municipality[]>(url)
      .pipe(
        catchError(this.handleError<Municipality[]>(`get municipalities `, []))
      );
  }

  getMunicipalitiesAdmin(): Observable<Municipality[]> {
    const url = `${this.configService.config.apiUrl}/Trip/AdAuth/municipalities`;
    return this.http
      .get<Municipality[]>(url)
      .pipe(
        catchError(this.handleError<Municipality[]>(`get municipalities `, []))
      );
  }


  getStops(municipalityId: string): Observable<Stop[]> {
    const url = `${this.configService.config.apiUrl}/Trip/stops/${municipalityId}`;
    return this.http
      .get<Stop[]>(url)
      .pipe(catchError(this.handleError<Stop[]>(`get stops `, [])));
  }

  filterToStops(stopId: number, date: string) {
    const url = `${this.configService.config.apiUrl}/Trip/searchtrip/filtertostops`;
    const body = {'stopId': stopId, 'date': date};
    return this.http
      .post(url, body, { observe: 'response' })
      .pipe(tap());
  }

  filterFromStops(stopId: number, date: string) {
    const url = `${this.configService.config.apiUrl}/Trip/searchtrip/filterfromstops`;
    const body = {'stopId': stopId, 'date': date};
    return this.http
      .post(url, body, { observe: 'response' })
      .pipe(tap());
  }

  searchTrip(trip: Trip) {
    const url = `${this.configService.config.apiUrl}/Trip/searchtrip`;
    return this.http
      .post(url, trip, { observe: 'response' })
      .pipe(tap());
  }

  /** Error Handling */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      return of(result as T);
    };
  }
}
