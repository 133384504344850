import { Order } from './../models/order';
import { PlaceOrder } from './../models/placeOrder';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from 'src/configservice';
import { AdminOrderCancellationRequest } from '../models/admin-order-cancellation-request';
//import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  userId: string;
  //private orderUrl = 'https://localhost:44307/api/Order';

  constructor(private http: HttpClient, private configService: ConfigService) {}

  getOrders(userId: string): Observable<Order[]> {
    const url = `${this.configService.config.apiUrl}/Order/user/${userId}`;
    return this.http
      .get<Order[]>(url)
      .pipe(catchError(this.handleError<Order[]>(`get orders `, [])));
  }

  getAllOrders(): Observable<Order[]> {
    const url = `${this.configService.config.apiUrl}/Order/AdAuth/all`;
    return this.http
      .get<Order[]>(url)
      .pipe(catchError(this.handleError<Order[]>(`get all orders `, [])));
  }

  placeOrder(order: PlaceOrder) {
    const url = `${this.configService.config.apiUrl}/Order`;
    return this.http
      .post(url, order, { observe: 'response'});
  }

  cancelOrder(ticketIds: string[]) {
    const url = `${this.configService.config.apiUrl}/Order/ticket/cancel`;
    return this.http
      .post(url, ticketIds, { observe: 'response' })
      .pipe(tap((_) => console.log(`canceled order`)));
  }

  cancelOrderSingleTicket(ticketId: string) {
    const url = `${this.configService.config.apiUrl}/Order/ticket/${ticketId}/cancel`;
    return this.http
      .post(url, null, { observe: 'response' })
      .pipe(tap((_) => console.log(`canceled order`)));
  }

  //Cancel order through admin panel
  cancelOrderAdmin(request: AdminOrderCancellationRequest) {
    const url = `${this.configService.config.apiUrl}/Order/AdAuth/ticket/cancel`;
    return this.http
      .post(url, request, { observe: 'response' })
      .pipe(tap((_) => console.log(`canceled order`)));
  }

  /** Error Handling */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      return of(result as T);
    };
  }

}
