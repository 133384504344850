import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../models/user';
import { catchError, map, tap } from 'rxjs/operators';
import { Relative } from '../models/relative';
import { ConfigService } from 'src/configservice';
//import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userId: string;
  //private usersUrl = 'https://localhost:44307/api/User';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response',
  };

  constructor(private http: HttpClient, private configService: ConfigService) {}

  /** Get user by ID */
  getUser(id: string): Observable<User> {
    const url = `${this.configService.config.apiUrl}/User/${id}`;
    return this.http
      .get<User>(url)
      .pipe(catchError(this.handleError<User>(`getUser id=${id}`)));
  }

  /** Update User */
  updateUser(userId: string, user: User): Observable<any> {
    const url = `${this.configService.config.apiUrl}/User/${userId}/edit`;
    return this.http
      .post(url, user, { observe: 'response' })
      .pipe(catchError(this.handleError<any>(`update user`)));
  }

  /** Add Relative to User */
  addRelative(userId: string, relative: Relative): Observable<any> {
    const url = `${this.configService.config.apiUrl}/User/${userId}/relative`;
    return this.http
      .post<Relative>(url, relative, { observe: 'response' })
      .pipe(catchError(this.handleError<any>(`add relative`, relative)));
  }

  /** Update Relative */
  updateRelative(
    userId: string,
    relativeId: string,
    relative: Relative
  ): Observable<any> {
    const url = `${this.configService.config.apiUrl}/User/${userId}/relative/${relativeId}/edit`;
    return this.http.post(url, relative, { observe: 'response' }).pipe(
      tap((_) => console.log(`updated relative `)),
      catchError(this.handleError<any>(`update relative`))
    );
  }

  /** Delete relative from User */
  removeRelative(userId: string, relativeId: string): Observable<any> {
    const url = `${this.configService.config.apiUrl}/User/${userId}/relative/${relativeId}`;
    return this.http.delete(url, { observe: 'response' });
  }

  /** Error Handling */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      return of(result as T);
    };
  }
}
